
window.CaptchaReadyLoad = function (action, callback) {
    window.grecaptcha.ready(function () {
        window.grecaptcha.execute(window.googleCaptchaSiteKey, { action: action }).then(function (token) {
            if (typeof callback === "function")
                callback(token);
            else {
                return token;
            }
        });
    });
}

window.LoadRepatcha = function (action, callback) {
    if (window.grecaptcha) {
        return CaptchaReadyLoad(action, callback);
    }

    return LoadRepatchaScript(function () {
        return CaptchaReadyLoad(action, callback);
    })
};

window.SetCaptcha = function (xhr) {
    if (event.target.classList.contains("googleCaptchaValidate")) {

        xhr.abort();
        var targetForm = event.target;
        var action = new URL(targetForm.action).pathname;
        var submitter = event.submitter;
        LoadRepatcha(action,
            function (token) {
                var element = targetForm.querySelector('[id$="Recaptcha"]');
                targetForm.classList.remove("googleCaptchaValidate");
                element.value = token;
                submitter.click();
            });

        return false;
    }

    return true;
};

window.LoadRepatchaScript = function (callback) {
    jQuery.getScript("https://www.google.com/recaptcha/api.js?render=" + googleCaptchaSiteKey, function () {
        if (jQuery.isFunction(callback)) {
            callback();
        }
    });
};

window.LoadCaptchaForBlazorWhenReady = async function (action) {

    await window.grecaptcha.ready(function () { });

    const token = await window.grecaptcha.execute(window.googleCaptchaSiteKey, { action: action });

    return token;
};

window.LoadCaptchaForBlazor = async function (action) {
    if (!window.grecaptcha) {
        // Load the reCAPTCHA script if it's not already loaded
        await window.LoadRepatchaScriptAsync();
        // Adding a delay to ensure grecaptcha object is available
        await new Promise(resolve => setTimeout(resolve, 500));
    }
    // Once it's confirmed that grecaptcha is available, call LoadCaptchaForBlazorWhenReady
    return await window.LoadCaptchaForBlazorWhenReady(action);
};

window.LoadRepatchaScriptAsync = async function () {
    return new Promise((resolve, reject) => {
        jQuery.getScript("https://www.google.com/recaptcha/api.js?render=" + window.googleCaptchaSiteKey)
            .done(resolve)
            .fail(reject);
    });
};
